import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/index/logo_w.png';
import _imports_1 from '@/assets/images/login_bg.png';
const _hoisted_1 = {
  class: "acea-row row-between-wrapper Logins"
};
const _hoisted_2 = {
  class: "inLogin acea-row row-center-wrapper"
};
const _hoisted_3 = {
  key: 0,
  class: "login"
};
const _hoisted_4 = {
  class: "login-title"
};
const _hoisted_5 = {
  class: "ml-13 mb-7"
};
const _hoisted_6 = {
  class: "ml-13 mb-7"
};
const _hoisted_7 = {
  class: "login-foot__text"
};
const _hoisted_8 = {
  key: 1
};
import { useUserStore } from '@/stores/user';
import { login } from '@/http';
import { ref } from 'vue';
import { showToast } from 'vant';
import { router } from '@/router';
import register from "@/view/register.vue";
export default {
  __name: 'login',
  setup(__props) {
    const showLogins = ref(true);
    const showPwd = ref(false);
    const formData = ref({
      email: '',
      password: ''
    });
    const loading = ref(false);
    const userStore = useUserStore();
    const _login = () => {
      loading.value = true;
      login(formData.value).then(({
        code,
        msg,
        data
      }) => {
        loading.value = false;
        showToast(msg);
        if (code === 200) {
          userStore.setToken(data.token);
          userStore.setUserinfo(data.user);
          router.push('/home?id=0');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }).catch(() => {
        loading.value = false;
      });
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_button = _resolveComponent("van-button");
      const _component_el_link = _resolveComponent("el-link");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "left"
      }, [_createElementVNode("img", {
        class: "logo",
        src: _imports_0,
        alt: ""
      }), _createElementVNode("img", {
        class: "bg",
        src: _imports_1,
        alt: ""
      })], -1)), _createElementVNode("div", _hoisted_2, [showLogins.value ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('login_account')), 1), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('email')), 1), _createVNode(_component_van_field, {
        class: "input mb-20",
        modelValue: formData.value.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formData.value.email = $event),
        placeholder: _ctx.$t('input_email_account'),
        border: false
      }, null, 8, ["modelValue", "placeholder"]), _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('password')), 1), _createVNode(_component_van_field, {
        class: "input mb-60",
        modelValue: formData.value.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formData.value.password = $event),
        type: showPwd.value ? 'text' : 'password',
        placeholder: _ctx.$t('input_login_password'),
        "right-icon": showPwd.value ? 'closed-eye' : 'eye-o',
        onClickRightIcon: _cache[2] || (_cache[2] = $event => showPwd.value = !showPwd.value),
        border: false
      }, null, 8, ["modelValue", "type", "placeholder", "right-icon"]), _createVNode(_component_van_button, {
        style: {
          "color": "#333"
        },
        class: "login-box__shadow mb-22",
        color: "#56F9AA",
        round: "",
        block: "",
        onClick: _cache[3] || (_cache[3] = $event => _login()),
        loading: loading.value
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('login')), 1)]),
        _: 1
      }, 8, ["loading"]), _createElementVNode("div", _hoisted_7, [_createTextVNode(_toDisplayString(_ctx.$t('no_account_yet')) + "？ ", 1), _createVNode(_component_el_link, {
        type: "primary",
        onClick: _cache[4] || (_cache[4] = $event => showLogins.value = false)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('register_now')), 1)]),
        _: 1
      })])])) : (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(register, {
        onReturnLogin: _cache[5] || (_cache[5] = $event => showLogins.value = true)
      })]))])]);
    };
  }
};